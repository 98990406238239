import Modal from '@/components/Modal/Modal'
import profileService from '@/services/profile.service'
import { CardType } from '@/types/card'

import styles from './AlternateArtModal.module.scss'

type Props = {
  card: CardType
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const AlternateArtModal = ({ card, isOpen, setIsOpen }: Props) => {
  const handleArtSelection = async (url: string | null | undefined, image_uris: string[]) => {
    if (!url) return console.warn(`No url for ${card.name}`)
    await profileService.update({ alternateArt: { [url]: image_uris } })
    setIsOpen(false)
  }

  return (
    <>
      {isOpen && (
        <Modal
          className={styles.outerDialog}
          dialogClassName={styles.dialog}
          onHide={() => setIsOpen(false)}
          show={isOpen}
        >
          <Modal.Body>
            <div className={styles.cards}>
              {card.unique_artwork?.map(({ artist, collector_number, image_uris, set, set_name }, i) => (
                <div className={styles.cardContainer} key={i} onClick={() => handleArtSelection(card.url, image_uris)}>
                  <div>
                    <img alt={`${set} ${collector_number}`} className={styles.card} src={image_uris[0]} />
                  </div>
                  <div className='text-center'>
                    <div className={styles.set}>{set_name}</div>
                    <div>{artist}</div>
                  </div>
                </div>
              ))}
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

export default AlternateArtModal
