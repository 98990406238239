import classNames from 'classnames'
import Link from 'next/link'
import { useState } from 'react'
import createBreakpoint from 'react-use/lib/factory/createBreakpoint'

import { ScryfallImageType } from '@/types/card'

import styles from './CardImage.module.scss'

const useBreakpoint = createBreakpoint({ sm: 576, xs: 0 })

type CardImageWrapperProps = {
  children: React.ReactNode
  onClick?: () => void
  target?: string
  url?: string | null
}

const CardImageWrapper = ({ children, onClick, target, url }: CardImageWrapperProps) =>
  onClick ? (
    <div onClick={onClick}>{children}</div>
  ) : url ? (
    <Link href={url} target={target}>
      {children}
    </Link>
  ) : (
    children
  )

type Props = {
  children?: React.ReactNode
  image_uris: string[] | ScryfallImageType[]
  isRotated: boolean
  name: string
  names: string[]
  onLoad: () => void
  onlyBack?: boolean
  onlyFront?: boolean
  reverse?: boolean
  scalable?: boolean
  sideBySide?: boolean
  target?: string
  url?: string
}

const CardImage = ({
  children,
  image_uris,
  isRotated,
  name,
  onLoad,
  onlyBack,
  onlyFront,
  reverse,
  scalable,
  sideBySide,
  target,
  url,
}: Props) => {
  const breakpoint = useBreakpoint()
  const [selectedIndex, setSelectedIndex] = useState(0)

  const backIndex = reverse ? 0 : 1
  const frontIndex = reverse ? 1 : 0

  /* On desktop, hovering the back card brings it to the front, and clicking either card opens the page
     On mobile, clicking the front card opens the page, and clicking the back card brings it to the front */
  if (image_uris.length == 2 && !onlyBack && !onlyFront) {
    return (
      <div
        className={classNames(styles.container, {
          [styles.sideBySide]: sideBySide,
          [styles.scalable]: scalable,
        })}
      >
        <CardImageWrapper target={target} url={breakpoint === 'sm' || selectedIndex === frontIndex ? url : undefined}>
          <img
            alt={name}
            className={classNames(styles.border, styles.doubleImage, 'shadow-sm', {
              [styles.front]: !sideBySide,
              [styles.left]: sideBySide,
              [styles.rotate90]: isRotated,
              [styles.selected]: selectedIndex == frontIndex,
            })}
            onClick={
              breakpoint === 'xs' && selectedIndex !== frontIndex ? () => setSelectedIndex(frontIndex) : undefined
            }
            onLoad={onLoad}
            src={typeof image_uris[0] === 'string' ? image_uris[0] : image_uris[0].normal}
          />
        </CardImageWrapper>
        <CardImageWrapper target={target} url={breakpoint === 'sm' || selectedIndex === backIndex ? url : undefined}>
          <img
            alt={name}
            className={classNames(styles.border, styles.doubleImage, 'shadow-sm', {
              [styles.back]: !sideBySide,
              [styles.right]: sideBySide,
              [styles.selected]: selectedIndex == backIndex,
            })}
            onClick={breakpoint === 'xs' && selectedIndex !== backIndex ? () => setSelectedIndex(backIndex) : undefined}
            onLoad={onLoad}
            src={typeof image_uris[1] === 'string' ? image_uris[1] : image_uris[1].normal}
          />
        </CardImageWrapper>
        {children}
      </div>
    )
  }

  const imageIndex = onlyBack ? 1 : 0

  return (
    <div
      className={classNames(styles.container, {
        [styles.scalable]: scalable,
      })}
    >
      <CardImageWrapper target={target} url={url}>
        <img
          alt={name}
          className={classNames(styles.border, styles.regularImage, 'shadow-sm', {
            [styles.rotate90]: isRotated,
          })}
          onLoad={onLoad}
          src={typeof image_uris[imageIndex] === 'string' ? image_uris[imageIndex] : image_uris[imageIndex].normal}
        />
      </CardImageWrapper>
      {children}
    </div>
  )
}

export default CardImage
