import classNames from 'classnames'

import Icon from '@/components/Icon/Icon'

import styles from '../Card.module.scss'

type Props = {
  isRotated: boolean
  setIsRotated: (isRotated: boolean) => void
}

const RotateButton = ({ isRotated, setIsRotated }: Props) => (
  <div
    className={classNames(styles.cardButton, 'edhrec-clipboard-dont-close')}
    onClick={() => setIsRotated(!isRotated)}
  >
    <div className={classNames(styles.cardButtonInner, 'edhrec-clipboard-dont-close')}>
      <Icon className='d-flex text-white edhrec-clipboard-dont-close' icon={isRotated ? 'rotateLeft' : 'rotateRight'} />
    </div>
  </div>
)

export default RotateButton
