import classNames from 'classnames'

import filtersService from '@/services/filters.service'
import { useAppSelector } from '@/store/hooks'
import { CardType, PageCardType } from '@/types/card'

import styles from '../Card.module.scss'
import ClipboardButton from '../ClipboardButton/ClipboardButton'
import DropdownButton from '../DropdownButton/DropdownButton'
import RotateButton from '../RotateButton/RotateButton'

type Props = {
  card: CardType
  isRotated: boolean
  pageCommander?: PageCardType
  scalable?: boolean
  setIsRotated: (isRotated: boolean) => void
}

const CardButtons = ({ card, isRotated, pageCommander, scalable, setIsRotated }: Props) => {
  const nameFilters = useAppSelector((state) => state.filters.nameFilters)
  const nameFilterPreposition = filtersService.getNameFilterPreposition({ cardName: card.name, nameFilters })

  const className = classNames(styles.cardButton, 'edhrec-clipboard-dont-close', {
    [styles.scalableCardButton]: scalable,
    'd-flex': nameFilterPreposition !== null,
  })
  const classNameInner = classNames(styles.cardButtonInner, 'edhrec-clipboard-dont-close')

  return (
    <div className={classNames(styles.cardButtons, { [styles.scalableCardButtons]: scalable })}>
      <ClipboardButton card={card} className={className} classNameInner={classNameInner} />
      {['Battle — Siege', 'Enchantment — Room'].includes(card.type) && (
        <RotateButton isRotated={isRotated} setIsRotated={setIsRotated} />
      )}
      <DropdownButton card={card} className={className} classNameInner={classNameInner} pageCommander={pageCommander} />
    </div>
  )
}

export default CardButtons
