import classNames from 'classnames'
import { useEffect, useState } from 'react'
import LazyLoad from 'react-lazyload'

import bannedImg from '@/assets/banned.png'
import unofficialImg from '@/assets/unofficial.png'
import toastService from '@/services/toast.service'
import { useAppSelector } from '@/store/hooks'
import { GenericCardType } from '@/types/card'
import { CardType, PageCardType } from '@/types/card'
import { bracketsArticleUrl } from '@/utils/constants'
import { useCard } from '@/utils/fetcher'
import { formatUrl } from '@/utils/helpers'
import usePatreonTier from '@/utils/usePatreonTier'

import OutboundLink from '../OutboundLink/OutboundLink'

import styles from './Card.module.scss'
import CardButtons from './CardButtons/CardButtons'
import CardImage from './CardImage'
import CardLabel from './CardLabel/CardLabel'
import CardLoading from './CardLoading/CardLoading'
import CardPrices from './CardPrices/CardPrices'
import CardSalt from './CardSalt/CardSalt'

const gameChangers = [
  'Drannith Magistrate',
  'Enlightened Tutor',
  "Serra's Sanctum",
  'Smothering Tithe',
  'Trouble in Pairs',
  'Cyclonic Rift',
  'Expropriate',
  'Force of Will',
  'Fierce Guardianship',
  'Rhystic Study',
  "Thassa's Oracle",
  'Urza, Lord High Artificer',
  'Mystical Tutor',
  'Jin-Gitaxias, Core Augur',
  "Bolas's Citadel",
  'Demonic Tutor',
  'Imperial Seal',
  'Opposition Agent',
  'Tergrid, God of Fright',
  'Vampiric Tutor',
  'Ad Nauseam',
  "Jeska's Will",
  'Underworld Breach',
  "Gaea's Cradle",
  'Survival of the Fittest',
  'Vorinclex, Voice of Hunger',
  'The One Ring',
  'Trinisphere',
  'Chrome Mox',
  'Grim Monolith',
  "Lion's Eye Diamond",
  'Mox Diamond',
  'Mana Vault',
  'Ancient Tomb',
  'Glacial Chasm',
  'The Tabernacle at Pendrell Vale',
  'Kinnan, Bonder Prodigy',
  "Yuriko, the Tiger's Shadow",
  'Winota, Joiner of Forces',
  'Grand Arbiter Augustin IV',
]

type Props = {
  background?: boolean
  card?: GenericCardType
  className?: string
  fullCard?: CardType
  isPreview?: boolean
  label?: React.ReactNode
  name?: string
  new?: boolean
  onlyBack?: boolean
  onlyFront?: boolean
  pageCommander?: PageCardType
  reverse?: boolean
  scalable?: boolean
  sideBySide?: boolean
  target?: string
}

const CardLazy = (props: Props) => {
  const { card, fullCard, isPreview, name, onlyBack, onlyFront, pageCommander, reverse, scalable, sideBySide, target } =
    props
  const alternateArt = useAppSelector((state) => state.user.alternateArt)
  const patreonTier = usePatreonTier()
  const [imageIsLoaded, setImageIsLoaded] = useState(false)
  const [isRotated, setIsRotated] = useState(false)

  // console.log('CardLazy', props, alternateArt, patreonTier, imageIsLoaded, isRotated)

  useEffect(() => {
    setIsRotated(false)
  }, [card])

  // Fetch data
  const { data: fetchedData, error } = useCard({ card: card as GenericCardType, name })
  const data =
    fetchedData ||
    (fullCard ? { ...fullCard, url: fullCard.sanitized || formatUrl(fullCard.name || name || '') } : undefined)

  if (error) return isPreview ? <>Failed to load {<span className={styles.error}>{name}</span>}</> : null

  const { banned, new: isNew, prices, unofficial } = data || {}
  const isGameChanger = gameChangers.includes(data?.name || '')

  // image_uris
  let image_uris: string[] | undefined = undefined
  if (patreonTier === 'rare' && alternateArt && !name?.includes('|')) {
    if (card && card.cards && card.cards.length > 1 && card.is_partner) {
      image_uris = [0, 1].map((i) =>
        card.cards
          ? alternateArt[card.cards[i]?.url || '']
            ? alternateArt[card.cards[i]?.url || ''][0]
            : data
              ? data.image_uris[i]
              : ''
          : '',
      )
    } else if (data) {
      image_uris = alternateArt[data.url || data.sanitized || ''] || data.image_uris
    }
  } else if (data) {
    image_uris = data.image_uris
  }

  // If no url given, build a url from the name
  // If given url is null, pass in undefined (no link, Card is unclickable)
  // Otherwise, use the given url
  const url =
    card?.url !== undefined
      ? card.url === null
        ? undefined
        : card.url
      : `/${data?.legal_commander ? 'commanders' : 'cards'}/${formatUrl((name || data?.name)?.split('|')[0] || '')}`

  return (
    <>
      {banned ? (
        <div className={styles.banned}>
          <img alt='Banned' src={bannedImg.src} />
        </div>
      ) : unofficial ? (
        <div className={styles.banned}>
          <img alt='Unofficial' src={unofficialImg.src} />
        </div>
      ) : null}
      {data && (
        <>
          <CardButtons
            card={data}
            isRotated={isRotated}
            pageCommander={pageCommander}
            scalable={scalable}
            setIsRotated={setIsRotated}
          />
          {image_uris && (
            <CardImage
              image_uris={image_uris}
              isRotated={isRotated}
              name={data.name}
              names={data.names}
              onLoad={() => setImageIsLoaded(true)}
              onlyBack={onlyBack}
              onlyFront={onlyFront}
              reverse={reverse}
              scalable={scalable}
              sideBySide={sideBySide}
              target={target}
              url={url}
            >
              {isGameChanger ? (
                <OutboundLink url={bracketsArticleUrl}>
                  <div className={styles.gameChanger}>
                    <span>GC</span>
                  </div>
                </OutboundLink>
              ) : props.new || isNew ? (
                <div className={styles.new}>
                  <span>NEW</span>
                </div>
              ) : (
                <CardSalt salt={data.salt} />
              )}
            </CardImage>
          )}
        </>
      )}
      {!imageIsLoaded && <CardLoading />}
      {prices && <CardPrices names={data?.names} prices={prices} scalable={scalable} />}
    </>
  )
}

const Card = (props: Props) => {
  const namesUnderCards = useAppSelector((state) => state.user.namesUnderCards)

  const name = props.card?.name || props.name || props.fullCard?.name || ''

  // console.log('Card', props)

  return (
    <div
      className={classNames(styles.container, props.className, {
        [styles.sideBySide]: props.sideBySide,
        [styles.scalable]: props.scalable,
      })}
    >
      {!namesUnderCards && (
        <div
          className={classNames(styles.nameWrapper, {
            [styles.scalableNameWrapper]: props.scalable,
          })}
          onClick={() => {
            toastService.createToast(`Copied ${name}`)
            navigator.clipboard.writeText(name)
          }}
        >
          <span className={styles.name}>{name}</span>
        </div>
      )}
      <LazyLoad className={classNames({ [styles.sideBySide]: props.sideBySide })} height={359.19} once>
        <CardLazy {...props} pageCommander={props.pageCommander} />
      </LazyLoad>
      {namesUnderCards && <div className={styles.nameUnderCard}>{name}</div>}
      <CardLabel
        label={props.label || props.card?.label}
        num_decks={props.card?.num_decks}
        pageCommander={props.pageCommander}
        potential_decks={props.card?.potential_decks}
        synergy={props.card?.synergy}
      />
    </div>
  )
}

export default Card
