import classNames from 'classnames'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { v4 } from 'uuid'

import OutboundLink from '@/components/OutboundLink/OutboundLink'
import { useAppSelector } from '@/store/hooks'
import { PriceMapType } from '@/types/card'
import { tcgplayerLink } from '@/utils/helpers'

import styles from './CardPrices.module.scss'

type TCGPContainerProps = {
  children: any
  saleEndTime: string
  tcgplayerSale: number
}

const TCGPContainer = ({ children, saleEndTime, tcgplayerSale }: TCGPContainerProps) =>
  tcgplayerSale > 0 ? (
    <OverlayTrigger
      overlay={
        <Tooltip id='button-tooltip'>
          <div>On Sale Until</div>
          <div>{saleEndTime}</div>
        </Tooltip>
      }
      placement='top'
    >
      {children}
    </OverlayTrigger>
  ) : (
    children
  )

type Props = {
  names?: string[]
  prices: PriceMapType
  scalable?: boolean
}

const CardPrices = ({ names, prices, scalable }: Props) => {
  const { tcgplayerKickback, tcgplayerSale } = useAppSelector((state) => state.misc)
  const priceSources = useAppSelector((state) => state.user.priceSources)

  const scgSlug = encodeURIComponent(
    (names ? names.join(' // ') : '')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace('꞉', ':'), // Ratonhnhaké꞉ton uses a "colon sign" which is NOT a colon
  ).replace(/\s/g, '%20')

  const priceLinks: Partial<Record<keyof PriceMapType, () => React.ReactNode>> = {
    cardhoarder: () => (
      <OutboundLink key={v4()} title='Buy at Cardhoarder' url={prices.cardhoarder.url}>
        <div className={styles.price}>
          <span>{`${prices.cardhoarder.price} TIX`}</span>
        </div>
      </OutboundLink>
    ),
    cardkingdom: () => (
      <OutboundLink
        key={v4()}
        title='Buy at Card Kingdom'
        url={`${prices.cardkingdom.url}&partner_args=edhrec,card`}
        utm_medium='affiliate'
      >
        <div className={styles.price}>
          <svg
            height='12'
            version='1.1'
            viewBox='0 0 3.7041667 3.1750001'
            width='14'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g transform='translate(0 -293.82)'>
              <path d='m0 293.82v1.1592h0.52042v2.0158h2.6633v-2.0158h0.52042v-1.1592h-0.57636v1.0908h-0.49196v-1.084h-0.54839v1.084h-0.47074v-1.084h-0.54839v1.084h-0.49196v-1.0908z' />
            </g>
          </svg>
          <span>{`$${prices.cardkingdom.price.toFixed(2)}`}</span>
        </div>
      </OutboundLink>
    ),
    cardmarket: () => (
      <OutboundLink
        key={v4()}
        title='Buy at Cardmarket'
        url={`${prices.cardmarket.url}&utm_source=edhrec&utm_medium=text&utm_campaign=card_prices`}
        utm_medium='text'
      >
        <div className={styles.price}>
          <span>
            {`${
              typeof prices.cardmarket.price === 'number' ? prices.cardmarket.price.toFixed(2) : prices.cardmarket.price
            } €`.replace('.', ',')}
          </span>
        </div>
      </OutboundLink>
    ),
    face2face: () => (
      <OutboundLink key={v4()} title='Buy at Face to Face Games' url={prices.face2face.url} utm_medium='affiliate'>
        <div className={styles.price}>
          <svg
            enableBackground='new 0 0 864 1350.73'
            version='1.1'
            viewBox='0 0 864 1350.7'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g id='XMLID_986_'>
              <path
                className='st0'
                d='M864,796.46H745.11c0,170.19-159.65,291.87-257.24,331.73v146.56   C617.34,1225.6,864,1043.43,864,796.46z'
                id='XMLID_991_'
              />
              <path
                className='st0'
                d='M119.2,796.46H0.29c0,246.6,247.19,428.61,376.15,478.09v-145.82   C276.98,1089.6,119.2,968.16,119.2,796.46z'
                id='XMLID_990_'
              />
              <path
                className='st0'
                d='M119.37,538.51h256.92V419.72H119.37V267.97h256.92V101.88h-0.94   c-55.4,37.7-119.22,59.29-187.2,59.29c-68,0-131.82-21.59-187.21-59.29H0v666.79h119.37V538.51z'
                id='XMLID_989_'
              />
              <path
                className='st0'
                d='M744.64,419.72H487.72v118.79h256.91v230.16H864V101.88h-0.93   c-55.4,37.7-119.22,59.29-187.21,59.29c-67.98,0-131.81-21.59-187.2-59.29h-0.94v166.09h256.91V419.72z'
                id='XMLID_988_'
              />
              <path
                className='st0'
                d='m404.97 330.4h-109.98c-4.08-5.85-10.79-9.68-18.41-9.68-12.46 0-22.54 10.17-22.54 22.71 0 12.55 10.08 22.72 22.54 22.72 7.62 0 14.33-3.84 18.4-9.69h110v918.31l27.03 75.97 27.04-75.97v-918.32h109.99c4.08 5.85 10.79 9.69 18.43 9.69 12.43 0 22.52-10.17 22.52-22.72 0-12.54-10.09-22.71-22.52-22.71-7.64 0-14.35 3.83-18.43 9.68h-110v-268.08c6.19-6.62 10.04-15.45 10.04-25.23 0.01-20.48-16.59-37.08-37.06-37.08-20.49 0-37.08 16.6-37.08 37.08 0 9.78 3.85 18.61 10.04 25.23-0.01 37.71-0.01 143.68-0.01 268.09z'
                id='XMLID_987_'
              />
            </g>
          </svg>
          <span>${prices.face2face.price.toFixed(2)}</span>
        </div>
      </OutboundLink>
    ),
    /* mtgstocks: () => (
      <OutboundLink
        key={v4()}
        title='View on MTGStocks'
        url={prices.mtgstocks.url}
      >
        <div className={styles.price}>
          <span>📈${prices.mtgstocks.price.toFixed(2)}</span>    TODO: CA conversion
        </div>
      </OutboundLink>
    ), */
    manapool: () => (
      <OutboundLink
        key={v4()}
        title='Buy at Mana Pool'
        url={`https://manapool.com/card/${prices.manapool.slug}?ref=scm&tap_s=5258585-0b68aa&tm_medium=edhrec,cardprice`}
        utm_medium='affiliate'
      >
        <div className={styles.price}>
          <svg height='14' version='1.1' viewBox='0 0 14 14' width='14' xmlns='http://www.w3.org/2000/svg'>
            <polygon
              points='83.315 67.948 83.315 57.885 63.837 63.216 63.837 73.28'
              transform='matrix(.122 0 0 .122 .064316 -.025459)'
            />
            <path d='m8.1912 0-7.1912 1.9626v9.636l4.7852 2.4013 7.3737-2.0227 8e-6 -9.636zm3.5092 9.5177-3.5887 1.0043v1.4176l-1.8054 0.47208v-7.7871l2.697 1.0006 2.6971-2.4769z' />
          </svg>
          <span>${prices.manapool.price.toFixed(2)}</span>
        </div>
      </OutboundLink>
    ),
    scg: () => (
      <OutboundLink
        disableUtm
        key={v4()}
        title='Buy at Star City Games'
        url={`https://starcitygames.com/search/?card_name=${scgSlug}&sort=priceasc&aff=6&utm_source=edhrec&utm_medium=affiliate`}
      >
        <div className={styles.price}>
          <svg height='14' version='1.1' viewBox='0 0 15 14' width='16'>
            <path d='M 7.412973,11.004101 3.14967,13.97079 C 3.025819,14.057072 2.862648,13.938475 2.906425,13.794088 L 4.410465,8.8227021 C 4.429725,8.7590251 4.407265,8.6899365 4.354275,8.6498203 L 0.2153,5.5116816 C 0.094951,5.420465 0.15735,5.2287986 0.30827,5.2256148 L 5.50125,5.1197525 C 5.56779,5.1183225 5.626534,5.0758155 5.648343,5.0129351 L 7.3536,0.1069767 c 0.04951,-0.1426355 0.251204,-0.1426355 0.300713,0 L 9.35973,5.0130943 c 0.02181,0.062881 0.08055,0.1055439 0.147092,0.1068174 l 5.192981,0.1058623 c 0.150913,0.00302 0.213157,0.1948501 0.09297,0.2860668 l -4.138975,3.1379795 c -0.05301,0.040275 -0.07546,0.1092053 -0.05619,0.1728818 l 1.50404,4.9713859 c 0.04378,0.144387 -0.119393,0.262984 -0.243244,0.176702 l -4.26331,-2.966689 c -0.0546,-0.03805 -0.127194,-0.03805 -0.181797,0 z' />
          </svg>
          <span>${prices.scg.price}</span>
        </div>
      </OutboundLink>
    ),
    tcgl: () => (
      <OutboundLink
        key={v4()}
        title='Buy at TCG Land'
        url={`https://www.tcg.land/product/mtg_single_${prices.tcgl.slug}?via=cardshops-edhrec-com`}
        utm_medium='affiliate'
      >
        <div className={styles.price}>
          <svg height='14' version='1.1' viewBox='0 0 10 14' width='10'>
            <g transform='matrix(.032377 0 0 .032377 -6.0205 -24.2)'>
              <path d='m494.63 901.83c0 85.3-154.4 278-154.4 278s-154.4-192.7-154.4-278 69.1-154.4 154.4-154.4 154.4 69.1 154.4 154.4z' />
            </g>
          </svg>
          <span>${prices.tcgl.price} MXN</span>
        </div>
      </OutboundLink>
    ),
    tcgplayer: () => {
      const end = tcgplayerKickback ? new Date(tcgplayerKickback.end) : new Date()
      const saleEndTime = `${end.getHours() % 12}:${end.getMinutes().toString().padStart(2, '0')} ${
        end.getHours() > 11 ? 'P' : 'A'
      }M ${end.toLocaleDateString()}`
      return (
        <TCGPContainer key={v4()} saleEndTime={saleEndTime} tcgplayerSale={tcgplayerSale}>
          <a
            href={tcgplayerLink({ subId1: 'edhrec,cardprice', u: prices.tcgplayer.url.split('?')[0] })}
            rel='noopener noreferrer'
            target='_blank'
          >
            <div className={classNames(styles.price, { [styles.sale]: tcgplayerSale > 0 })}>
              <svg height='12' id='svg708' version='1.1' viewBox='0 0 16 12' width='16.271'>
                <g strokeWidth='0px'>
                  <path d='m3.1428 1.0847-2.3024 0.34169c-0.53966 0.078644-0.90847 0.57763-0.82983 1.1119l1.1498 7.6746c0.081356 0.53424 0.58034 0.90305 1.12 0.82441l0.86237-0.12746z' />
                  <path d='m15.43 1.4264-2.2725-0.33627v9.8251l0.83254 0.12474c0.53695 0.07864 1.0386-0.29017 1.12-0.82441l1.1498-7.6746c0.08136-0.53424-0.29017-1.0332-0.82983-1.1119z' />
                  <path d='m5.079 3.4712' />
                  <path d='m5.079 3.4685' />
                  <path
                    d='m4.7455 0h6.8122c0.54508 0 0.98441 0.43932 0.98441 0.97898v10.042c0 0.54237-0.44203 0.97898-0.98441 0.97898h-6.8122c-0.54508 0-0.98441-0.43932-0.98441-0.97898v-10.042c0-0.54237 0.44203-0.97898 0.98441-0.97898zm4.1383 4.8759h2.0692c0.19797 0 0.31458 0.22237 0.20339 0.38508l-2.9451 4.2766c-0.1383 0.19797-0.45017 0.10305-0.45017-0.13831v-2.2183h-2.4136c-0.1817 0-0.30102-0.18983-0.22237-0.35254l2.0339-4.1763c0.040678-0.084068 0.12746-0.13831 0.22237-0.13831h2.259c0.1817 0 0.30102 0.18983 0.22237 0.35254z'
                    fillRule='evenodd'
                  />
                </g>
              </svg>
              <span>{`$${(((100 - tcgplayerSale) / 100) * prices.tcgplayer.price).toFixed(2)}`}</span>
            </div>
          </a>
        </TCGPContainer>
      )
    },
  }
  return (
    <div className={classNames(styles.prices, scalable && styles.scalable)}>
      {prices && (
        <>
          {[0, 1, 2].map((i) => {
            const priceSource = priceSources[i]
            return prices[priceSource] && priceLinks[priceSource] ? (
              (priceLinks[priceSource] as any)()
            ) : (
              <span key={v4()}>&nbsp;</span>
            )
          })}
        </>
      )}
    </div>
  )
}

export default CardPrices
