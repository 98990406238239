import classNames from 'classnames'
import { useRouter } from 'next/router'
import { useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { v4 } from 'uuid'

import AlternateArtModal from '@/components/AlternateArtModal/AlternateArtModal'
import Icon from '@/components/Icon/Icon'
import filtersService from '@/services/filters.service'
import { useAppSelector } from '@/store/hooks'
import { CardType, PageCardType } from '@/types/card'
import useDirectories from '@/utils/useDirectories'
import usePatreonTier from '@/utils/usePatreonTier'

import CustomMenu from './CustomMenu'
import CustomToggle from './CustomToggle'
import styles from './DropdownButton.module.scss'

type Props = {
  card: CardType
  className: string
  classNameInner: string
  pageCommander?: PageCardType
}

const DropdownButton = ({ card, className, classNameInner, pageCommander }: Props) => {
  const nameFilters = useAppSelector((state) => state.filters.nameFilters)
  const { dir } = useDirectories()
  const patreonTier = usePatreonTier()
  const router = useRouter()
  const [alternateArtIsOpen, setAlternateArtIsOpen] = useState(false)
  const filtersEnabled = pageCommander && (dir === 'commanders' || dir === 'decks')

  const enabled = patreonTier === 'rare'
  const alternateArtEnabled = card.unique_artwork && card.unique_artwork.length >= 2

  if (!enabled || (!alternateArtEnabled && !pageCommander)) return null

  const handleSubmit = () => {
    if (pageCommander) filtersService.submit({ card: pageCommander, dir, router })
  }

  // Alternate art button
  if (alternateArtEnabled && !filtersEnabled) {
    return (
      <>
        <AlternateArtModal card={card} isOpen={alternateArtIsOpen} setIsOpen={setAlternateArtIsOpen} />
        <div className={classNames(className, styles.alternateArtButton)} onClick={() => setAlternateArtIsOpen(true)}>
          <div className={classNames(classNameInner, 'd-flex')}>
            <Icon icon='eye' />
          </div>
        </div>
      </>
    )
  }

  // Dropdown button
  const nameFilterPreposition = filtersService.getNameFilterPreposition({ cardName: card.name, nameFilters })
  const iconColor =
    nameFilterPreposition === 'in' ? 'lightgreen' : nameFilterPreposition === 'out' ? 'lightpink' : 'white'
  return (
    <>
      <AlternateArtModal card={card} isOpen={alternateArtIsOpen} setIsOpen={setAlternateArtIsOpen} />
      <Dropdown className={classNames(styles.container)}>
        <Dropdown.Toggle
          as={CustomToggle}
          className={classNames(className, { 'd-flex': nameFilterPreposition !== null })}
          classNameInner={classNameInner}
        >
          <Icon className='d-flex edhrec-clipboard-dont-close' color={iconColor} icon='ellipsisV' />
        </Dropdown.Toggle>
        <Dropdown.Menu as={CustomMenu} className={classNames(classNameInner, styles.dropdown)}>
          {pageCommander && (
            <>
              <Dropdown.Header>Deck Filters</Dropdown.Header>
              <Dropdown.Item
                eventKey='1'
                onClick={() => {
                  filtersService.addFilter({
                    filter: { key: v4(), name: [card.name], preposition: 'in' },
                    type: 'name',
                  })
                  handleSubmit()
                }}
              >
                <Icon color='green' icon='squarePlus' /> <div className={styles.filterText}>With {card.name}</div>
              </Dropdown.Item>
              <Dropdown.Item
                eventKey='2'
                onClick={() => {
                  filtersService.addFilter({
                    filter: { key: v4(), name: [card.name], preposition: 'out' },
                    type: 'name',
                  })
                  handleSubmit()
                }}
              >
                <Icon color='red' icon='squareMinus' />
                <div className={styles.filterText}>Without {card.name}</div>
              </Dropdown.Item>
            </>
          )}
          {pageCommander && alternateArtEnabled && <Dropdown.Divider />}
          {alternateArtEnabled && (
            <Dropdown.Item eventKey='3' onClick={() => setAlternateArtIsOpen(true)}>
              <Icon icon='eye' /> <div className={styles.filterText}>View Alternate Art</div>
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}

export default DropdownButton
