import useSWR, { Fetcher } from 'swr'

import cardService from '@/services/card.service'
import { CardType, GenericCardType } from '@/types/card'

type ArgType = { card?: GenericCardType; name?: string }

const fetcher: Fetcher<CardType, ArgType> = async ({ card, name }: ArgType): Promise<CardType> => {
  if (card?.cards && card?.is_partner) {
    const res1 = await cardService.getCard(card?.cards[0]?.url as string)
    const res2 = await cardService.getCard(card?.cards[1]?.url as string)
    if ((res1 as any)?.message) {
      throw new Error((res1 as any)?.message)
    } else if ((res2 as any)?.message) {
      throw new Error((res2 as any)?.message)
    } else {
      return {
        ...res1,
        ...card,
        cards: [res1, res2],
        image_uris: [res1.image_uris[0], res2.image_uris[0]],
      }
    }
  } else {
    const res = await cardService.getCard(card?.sanitized_wo || card?.sanitized || card?.name || name || '')
    if ((res as any).message) {
      throw (res as any).message
    } else {
      return { ...res, typo: res?.name !== name?.split('|')[0] }
    }
  }
}

export const useCard = ({ card, name }: ArgType) => useSWR({ card, name }, fetcher)
